import React, { useEffect } from 'react'
import moment from 'moment'
import Typography from 'antd/lib/typography'
import PriceLabel from 'components/Label/PriceLabel'
import { useDispatch, useSelector } from 'react-redux'
import colors from 'providers/theme/config/colorPallete'
import { Fuel } from 'assets/svg'
import Tooltip from 'antd/lib/tooltip'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { requestPatchQuote, clearPatchQuote } from 'store/duck/patchQuote.duck'
import { getStateLaneHistory } from 'utils/formatValues'
import { Spin } from 'antd';
import { convertToNumber } from 'utils/to'
import { StyledDiv } from '../styles'
import actionsDropdown from './actionsDropdown'


const { Text } = Typography

const LoadWon = ({ loadWon = false, id = '', index = "",
  handleRow = (some) => some,
  isFetching = false }) => {
  const dispatch = useDispatch()
  const { success, rate } = useSelector((state) => state.patchQuote)

  useEffect(() => {
    if (success && rate?._id) {
      if (rate?._id === id) {
        handleRow(index, true, true)
        dispatch(clearPatchQuote())
      }
    }
  }, [success, id, dispatch, rate, isFetching, index, handleRow])


  const onChange = (e) => {
    handleRow(index, isFetching)
    dispatch(requestPatchQuote(id, e.target.checked))
  }

  if (isFetching) {
    return <Spin />
  }
  return <Checkbox checked={loadWon} onChange={onChange} style={{ paddingTop: '20px' }} />
}

export default function (addDatRate = false, handleRow = (some) => some) {
  const cols = [
    {
      title: <div style={{ fontSize: '16px' }}>Creation Date</div>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      ellipsis: true,
      render: (createdAt,record) => {
        return (
          <div style={{ fontSize: '16px' }}>
            <div style={{ color: colors.dark_grey.default, marginBottom: 4 }}>
              {moment(createdAt).format('MM/DD/YYYY HH:mm')}
            </div>
            <div style={{ color: '#A1A1A1' }}>
              Quote ID: {record.quoteId}
            </div>
          </div>
        )
      },
    },
    {
      title: <div style={{ fontSize: '16px' }}>Customer</div>,
      dataIndex: ['customer', 'name'],
      key: 'customer',
      width: 120,
      ellipsis: true,
      render: (customerName) => (
        <div style={{ color: colors.dark_grey.default, fontSize: '16px' }}>{customerName}</div>
      ),
    },

    {
      title: <div style={{ fontSize: '16px' }}>Origin</div>,
      dataIndex: 'lane',
      key: 'lane',
      width: 120,
      ellipsis: true,
      align: 'left',
      render: (lane, record) => {
        return (
          <StyledDiv>
            <Text>{getStateLaneHistory(record, 'origin')}</Text>
          </StyledDiv>
        )
      },
    },
    {
      title: <div style={{ fontSize: '16px' }}>Destination</div>,
      dataIndex: 'stops',
      key: 'stops',
      width: 120,
      ellipsis: true,
      align: 'left',
      render: (stops, record) => {
        return (
          <StyledDiv>
            <Text>{getStateLaneHistory(record, 'destination')}</Text>
          </StyledDiv>
        )
      },
    },
    {
      title: <div style={{ fontSize: '16px' }}>Pickup Date</div>,
      dataIndex: 'pickupDate',
      key: 'pickupDate',
      width: 120,
      ellipsis: true,
      align: 'left',
      render: (pickupDate) => {
        return (
          <StyledDiv>
            <Text>{moment(pickupDate, 'MM DD YYYY').format('MM-DD-YYYY')}</Text>
          </StyledDiv>
        )
      },
    },
    {
      title: <div style={{ fontSize: '16px' }}>Equipment</div>,
      dataIndex: ['truckTypeId', 'name'],
      key: 'truckTypeId',
      width: 100,
      ellipsis: true,
      align: 'left',
      render: (equipment) => <div style={{ fontSize: '16px' }}>{equipment}</div>,
    },
    {
      title: <div style={{ fontSize: '16px' }}>DAT</div>,
      dataIndex: ['details', 'base_rate'],
      key: 'details',
      width: 90,
      ellipsis: true,
      align: 'left',
      render: (baseRate) => {
        const base = baseRate ?? {}
        const value = base?.datRate?.datAvg?.rate
        return (
          <div style={{ lineHeight: '20px', display: 'flex', alignItems: 'center' }}>
            <PriceLabel>{convertToNumber(value)}</PriceLabel>
          </div>
        )
      },
    },
    {
      title: <div style={{ fontSize: '16px' }}>GSN</div>,
      dataIndex: ['details', 'base_rate', 'gsRate'],
      key: 'gsRate',
      width: 90,
      ellipsis: true,
      align: 'left',
      render: (gsRate) => {
        const value = gsRate?.rate
        return (
          <div style={{ lineHeight: '20px', display: 'flex', alignItems: 'center' }}>
            <PriceLabel>{convertToNumber(value)}</PriceLabel>
          </div>
        )
      },
    },
    {
      title: <div style={{ fontSize: '16px' }}>GST</div>,
      dataIndex: ['details', 'base_rate', 'gsPredictionRate'],
      key: 'gsPredictionRate',
      width: 90,
      ellipsis: true,
      align: 'left',
      render: (gsPredictionRate) => {
        const value = gsPredictionRate?.rate
        return (
          <div style={{ lineHeight: '20px', display: 'flex', alignItems: 'center' }}>
            <PriceLabel>{convertToNumber(value)}</PriceLabel>
          </div>
        )
      },
    },
    {
      title: <div style={{ fontSize: '16px' }}>Rate</div>,
      dataIndex: 'rate',
      key: 'rate',
      width: 80,
      ellipsis: true,
      align: 'left',
      render: (rate, record) => {
        const lh = record.LH ?? ''
        return (
          <div style={{ lineHeight: '20px', display: 'flex', alignItems: 'center' }}>
            <PriceLabel>$ {rate}</PriceLabel>
            {lh !== '' ? (
              <Tooltip
                placement="bottom"
                title={
                  <span style={{ color: '#545454' }}>
                    <Text style={{ fontStyle: 'italic', fontWeight: 600 }}>LH:</Text>
                    <Text style={{ fontStyle: 'italic' }}>{` $${record.LH}`}</Text>
                  </span>
                }
                color="#D9D9D9"
              >
                <img
                  alt="fuel"
                  style={{ marginLeft: '3px', width: '16px', height: '16px', paddingBottom: '1px' }}
                  src={Fuel}
                />
              </Tooltip>
            ) : null}
          </div>
        )
      },
    },
    {
      title: <div style={{ fontSize: '16px' }}>Won</div>,
      dataIndex: 'loadWon',
      key: 'loadWon',
      width: 50,
      ellipsis: true,
      align: 'center',
      render: (loadWon, record, index) => (
        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', position: 'relative' }}>
          <LoadWon
            loadWon={loadWon}
            id={record._id}
            index={index}
            handleRow={handleRow}
            isFetching={record?.isFetching ?? false} />
        </div>
      ),
    },

    {
      ...actionsDropdown(),
    },
  ]
  if (!addDatRate) {
    cols.splice(8, 1)
    cols.splice(7, 1)
    cols.splice(6, 1)
  }
  return cols
}
