// const data = {
//     "dataIndex": [
//         "rule",
//         "flat"
//     ],
//     "record": {
//         "_id": "65021b95d17374003278ad39",
//         "lastUsedDate": "2023-09-13T15:55:23.350Z",
//         "lastUpdateExpirationDate": null,
//         "expirationDate": null,
//         "crossRule": false,
//         "status": "Activated",
//         "deleted": false,
//         "isAlpha": false,
//         "typeRuleId": {
//             "_id": "5f99cae6701b45c20205363a",
//             "active": true,
//             "name": "Geographic",
//             "createdAt": "2020-07-10T14:02:24.223Z",
//             "updatedAt": "2020-08-19T22:40:12.931Z",
//             "__v": 0,
//             "deleted": false
//         },
//         "rule": {
//             "_id": "65021b95d17374003278ad37",
//             "margin": 10,
//             "flat": 20,
//             "guide": "Illinois",
//             "typeShippingId": "5f99cae6701b45c202053641",
//             "typeReferenceGeographicId": "5f0875e9d94d6a2f8a13c95c",
//             "createdAt": "2023-09-13T20:29:09.170Z",
//             "updatedAt": "2024-10-07T23:34:22.593Z",
//             "__v": 0
//         },
//         "ruleModel": "RuleGeographic",
//         "createdBy": "5f99cae6701b45c20205364c",
//         "name": "Master then Cust",
//         "createdAt": "2023-09-13T20:29:09.190Z",
//         "updatedAt": "2023-09-13T20:30:12.044Z",
//         "__v": 0,
//         "rulesetsInfo": {
//             "ruleSetsNames": [],
//             "quantity": 0
//         },
//         "multiStepsRuleInfo": {
//             "multistepNames": [],
//             "quantity": 0
//         },
//         "overlappingGeographicsRules": [
//             {
//                 "name": "inbound Illinois",
//                 "idRule": "605ca8f777b8c03bb7e72556",
//                 "idGeographic": "605ca8f777b8c03bb7e72554"
//             },
//             {
//                 "name": "outbound Illinois",
//                 "idRule": "605ca8f777b8c03bb7e72557",
//                 "idGeographic": "605ca8f777b8c03bb7e72555"
//             }
//         ]
//     }
// }

const handleDataForRule = (newData = {}, record = {}, type = "rule") => {
    let finalObject = { ...record }
    switch (type) {
        case "rule":
            finalObject = { ...finalObject, rule: { ...record.rule, ...newData } }
            break;
        case "multistep":
            finalObject = { ...finalObject, ...newData }
            break;

        default:
            break;
    }
    return finalObject
}

export const handlePayload = (record = {}, type = "rule", values={}) => {
    let finalObject = { id: record._id, }
    switch (type) {
        case "rule":
            finalObject = { ...finalObject, rule: { ...values } }
            break;
        case "multistep":
            finalObject = { ...finalObject, ...values }
            break;

        default:
            break;
    }
    return finalObject

}

export default handleDataForRule