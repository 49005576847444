export const getRouteMarkersValue = (hereData = {}, position = 0, props = [], mainProp = "routeMarkers") => {
        let value = ""
        if (hereData) {
            if (hereData[mainProp]) {
                const marker = hereData[mainProp][position]
                if (marker) {
                    let deepValue = marker
                    const size = props.length
                    for (let index = 0; index < size; index += 1) {
                        deepValue = deepValue[props[index]]
                    }
                    value = deepValue
                }
            }
        }
        return value
    
    }

export const totalMovementsFromRoutes = (routeData = {}) => {
    try {
        let total = 0
        const datas = routeData.data
        if (datas) {
            if (datas.routes) {
                if (datas.routes[0]) {
                    total = datas.routes[0].sections.length
                }
            }
        }
        return total
    } catch (error) {
        console.error({ from: "totalMovementsFromRoutes", payload: routeData })
        return 0
    }
}
 
