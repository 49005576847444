/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useContext, useRef, useMemo } from 'react'
import Form from 'antd/lib/form'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Space from 'antd/lib/space'
import InputNumber from 'antd/lib/input-number'
import { MdArrowDropDown, MdChevronLeft, MdChevronRight, MdSkipNext, MdSkipPrevious } from 'react-icons/md'
import BulkActions from 'components/BulkActions/BulkActions'
import colors from 'providers/theme/config/colorPallete'
import useWindowSize from 'hooks/useWindowSize'
import { get } from 'lodash'
import { AntdTable, TableContainer, AntdSelect } from './styles'
import { StyledButton } from '../MoreDropdown/styles'
import './style.css'
import handleDataForRule, { handlePayload } from './util/table.util'
// add editable functionality to editable cells
const EditableContext = React.createContext(null)
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}
const EditableCell = ({ request, title, editable, children, dataIndex, record, handleSave, rule, ...restProps }) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)
  const form = useContext(EditableContext)
  const field = rule === "rule" && dataIndex ? dataIndex[1] : dataIndex

  const nestedProperty = () => {
    let concatProprerty = ''
    for (const iterator of dataIndex) {
      concatProprerty += iterator
      concatProprerty += '.'
    }
    if (concatProprerty.length > 0) concatProprerty = concatProprerty.slice(0, -1)
    return concatProprerty
  }
  const evaluatedRecordValue = dataIndex ? record[dataIndex] : ""
  const previousValue = rule === "rule" && dataIndex ? get(record, nestedProperty()) : evaluatedRecordValue

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])



  const toggleEdit = () => {
    setEditing(!editing)
    // eslint-disable-next-line react/destructuring-assignment    
    form.setFieldsValue({
      [field]: previousValue,
    })
  }
  const save = async () => {
    try {
      // eslint-disable-next-line react/destructuring-assignment
      const values = await form.validateFields()
      toggleEdit()
      const val = handleDataForRule(values, record, rule)
      handleSave(val)
      if (typeof request === 'function') {
        const nextValue = handlePayload(record, rule, values)
        const prevValue = previousValue
        if (nextValue !== prevValue) {
          request(nextValue)
        }
      }

    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }
  let childNode = children
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={field}
        rules={[
          {
            required: true,
            message: `${title?.props?.children ?? ''} is required.`,
          },
        ]}
      >
        <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div onClick={toggleEdit} role="presentation">
        {children}
      </div>
    )
  }
  return <td {...restProps}>{childNode}</td>
}


/**
 *
 * @param {string} columns added column
 */
const Table = ({
  title = '',
  columns = [],
  data = [],
  loading,
  expandible,
  pagination,
  onChange,
  onChangeSelectedRows,
  rowKey,
  onRow,
  rowSelection,
  rowClassName,
  width,
  addHeight = 10,
  paddingBottom,
  size,
  updatedPagination,
  ruleSelected,
  dispatch,
  status,
  margin = 'auto',
  entityNameForBulk = 'rule',
  entityNameForTextOnTopOfTableForBulk = `${entityNameForBulk}s`,
  header,
  onExpand,
  expandedRowKeys,
  expandedRowRender,
  pauseRequest,
  deleteRequest,
  avoidNotify = false,
  CustomModalDelete,
  openPauseModal,
  openDeleteModal,
  handleData,
  /** Boolean indicating whether the button should render as disabled */
  limitPerPage = 20,
  paginationOptions = ['10', '20', '50', '100', '250'],
  bulkPadding = '',
  bulkMargin = '',
  pauseProps = {},
  deleteProps = {},
  showBulkActions = true,
  customBulkActions,
  bgHeader = '',
  borderTableNone = false,
  heightAntTable,
  alignRowTable,
  locale,
  heightContainer = '',
  request = '',
  displayComponent,
  enableEdit = true,
  rule = "rule"
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(limitPerPage)
  const [rows, setRows] = useState([])
  const { height: heightWindow } = useWindowSize()
  const [dataSource, setDataSource] = useState(data)
  const dataMemo = useMemo(() => data, [data])
  const [fieldEdited, setFieldEdited] = useState(false)

  useEffect(() => {
    if (dataSource.length === 0 && data.length > 0) {
      setDataSource(data) 
    }
    if (JSON.stringify(dataSource) !== JSON.stringify(dataMemo)) {
      setDataSource(data) 
    }
    if (JSON.stringify(dataSource) !== JSON.stringify(dataMemo) && fieldEdited) {
      setDataSource(dataSource) 
    }
  }, [data, dataSource, dataMemo, fieldEdited]) 

  const handleSave = (row) => {
    const newData = [...dataSource]
    const index = newData.findIndex((item) => row._id === item._id)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    setFieldEdited(true)
    setDataSource(newData)
  }
  const editableColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    }
  })
  useEffect(() => {
    if (updatedPagination) {
      onChange({ current: currentPage, limit })
    }
  }, [currentPage, limit, onChange, updatedPagination])
  const handleChangeLimit = (newLimit) => {
    setLimit(Number(newLimit))
    setCurrentPage(1)
  }
  useEffect(() => {
    handleChangeLimit(limit)
    setRows([])
  }, [limit, ruleSelected])
  useEffect(() => {
    setCurrentPage(1)
  }, [status])
  const rowSelec = {
    selectedRowKeys: rows,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKey) => {
      setRows(selectedRowKey)
    },
  }
  const cleanSelected = () => {
    setRows([])
  }
  useEffect(() => {
    onChangeSelectedRows(rows)
  }, [onChangeSelectedRows, rows])
  const heightVerified = Number(addHeight)
  const heightResponsive = Math.floor((heightWindow ?? 50) - 324 + heightVerified)
  return (
    <>
      {enableEdit && (
        <BulkActions
          padding={bulkPadding}
          margin={bulkMargin}
          data={rows}
          ruleSelected={ruleSelected}
          dispatch={dispatch}
          cleanData={cleanSelected}
          pauseRequest={pauseRequest}
          deleteRequest={deleteRequest}
          avoidNotify={avoidNotify}
          CustomModalDelete={CustomModalDelete}
          openPauseModal={openPauseModal}
          openDeleteModal={openDeleteModal}
          handleData={handleData}
          entityNameForBulk={entityNameForBulk}
          entityNameForTextOnTopOfTableForBulk={entityNameForTextOnTopOfTableForBulk}
          pauseProps={pauseProps}
          deleteProps={deleteProps}
          showBulkActions={showBulkActions}
          customBulkActions={customBulkActions}
          displayComponent={displayComponent}
        />
      )}

      <TableContainer
        margin={margin}
        width={width}
        paddingBottom={paddingBottom}
        borderTableNone={borderTableNone}
      >
        <div
          style={{
            width: '100%',
            overflow: 'auto',
            height: heightContainer === '' ? heightResponsive : heightContainer,
            paddingBottom: '0px',
          }}
        >
          <AntdTable
            components={{
              body: {
                row: EditableRow,
                cell: (props) => <EditableCell request={request} rule={rule} {...props} />,
              },
            }}
            title={title}
            expandedRowKeys={[expandedRowKeys]}
            onExpand={onExpand}
            header={header}
            bgHeader={bgHeader}
            size={size}
            expandable={expandible}
            loading={loading}
            columns={editableColumns}
            dataSource={dataSource}
            pagination={updatedPagination ? false : pagination}
            onChange={updatedPagination ? undefined : onChange}
            rowKey={rowKey}
            onRow={onRow}
            rowSelection={rowSelection ? rowSelec : null}
            rowClassName={rowClassName}
            expandIconColumnIndex={-1}
            expandedRowRender={expandedRowRender}
            expandIcon={() => null}
            heightAntTable={heightAntTable}
            alignRowTable={alignRowTable}
            locale={locale}
            tableLayout="fixed"
          />
        </div>
        {updatedPagination && (
          <Row justify="end" align="middle" style={{ marginTop: 12, marginRight: 16 }}>
            <Col>
              <span style={{ color: colors.dark_grey.default, fontSize: 16 }}>Rows per page</span>
              <AntdSelect
                value={limit}
                size="large"
                onChange={handleChangeLimit}
                style={{ width: 100, marginLeft: 15 }}
                suffixIcon={
                  <MdArrowDropDown
                    size="1.2rem"
                    color={colors.dark_blue.default}
                    style={{ position: 'absolute', top: 0, left: 0, transform: 'translate(-25%, -25%)' }}
                  />
                }
              >
                {paginationOptions.map((p, index) => (
                  <AntdSelect.Option key={index} value={p}>
                    {p}
                  </AntdSelect.Option>
                ))}
              </AntdSelect>
              <span style={{ marginLeft: 24, fontSize: 16, color: colors.dark_grey.default }}>
                {1 + (currentPage - 1) * limit}-{Math.min(currentPage * limit, pagination.total)} of{' '}
                {pagination.total}
              </span>
              <Space size="middle" style={{ marginLeft: 32 }}>
                <StyledButton disabled={currentPage === 1} shape="circle">
                  <MdSkipPrevious
                    size="24px"
                    onClick={() => setCurrentPage(() => 1)}
                    style={{ verticalAlign: 'middle', marginBottom: 4 }}
                  />
                </StyledButton>
                <StyledButton disabled={currentPage === 1} shape="circle">
                  <MdChevronLeft
                    size="24px"
                    onClick={() => setCurrentPage((page) => page - 1)}
                    style={{ verticalAlign: 'middle', marginBottom: 4 }}
                  />
                </StyledButton>
                <StyledButton disabled={1 + currentPage * limit > pagination.total} shape="circle">
                  <MdChevronRight
                    size="24px"
                    onClick={() => setCurrentPage((page) => page + 1)}
                    style={{ verticalAlign: 'middle', marginBottom: 4 }}
                  />
                </StyledButton>
                <StyledButton disabled={1 + currentPage * limit > pagination.total} shape="circle">
                  <MdSkipNext
                    size="24px"
                    onClick={() => setCurrentPage(() => Math.ceil(pagination.total / limit))}
                    style={{ verticalAlign: 'middle', marginBottom: 4 }}
                  />
                </StyledButton>
              </Space>
            </Col>
          </Row>
        )}
      </TableContainer>
    </>
  )
}

export default Table
