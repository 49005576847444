import React from 'react'
import { BorderSelect } from 'containers/rates/CreateRate/styles'
import CustomSelect from 'components/CustomSelect'
import Spin from 'antd/lib/spin'
import { FormItem } from 'components/Input'
import InputLineContainer from '../InputLineContainer'

export default function LineSelectCity({
  label = '',
  onSearch,
  options,
  disabled,
  onChange,
  index,
  fetchingData,
  name = '',
  dataTestid = '',
}) {

  return (
    <InputLineContainer
      dataTestid={dataTestid}
      label={label}
      Component={
        <FormItem name={name}>
          <CustomSelect
            disablesort="true"
            Custom={BorderSelect}
            bordered={false}
            dropdownRender={(menu) => <div>{menu}</div>}
            style={{ width: '100%' }}
            onSearch={onSearch}
            options={fetchingData ? [] : options}
            showSearch
            disabled={disabled}
            onChange={(values) => {
              onChange(values, index)
            }}
            loading={fetchingData}
            notFoundContent={fetchingData ? <Spin size="small" /> : null}
            suffixIcon={null}
            filterOption={(input, option) => {
              return (
                option?.value &&
                option.value
                  .replace(/[\s,]+/g, '')
                  .toLowerCase()
                  .indexOf(input.replace(/[\s,]+/g, '').toLowerCase()) >= 0
              )
            }}
          />
        </FormItem>
      }
    />
  )
}
